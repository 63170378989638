<template>
  <div>
    <Input
      label="Adı *"
      v-model="form.name"
      :defaultValue="selectedItem.name"
      :state="validateState('name')"
    />

    <div class="row">
      <div class="col-6">
        <Select
          label="Ürün *"
          v-model="form.productId"
          :defaultValue="selectedItem.productId"
          :options="products"
          :state="validateState('productId')"
        />
      </div>
      <div class="col-6">
        <NumberInput
          label="Ürün Fiyatı *"
          v-model="form.productPrice"
          :defaultValue="selectedItem.productPrice"
          :state="validateState('productPrice')"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <DateTime
          label="Başlama Tarihi *"
          v-model="form.startTime"
          :defaultValue="selectedItem.startTime"
          :state="validateState('startTime')"
        />
      </div>
      <div class="col-6">
        <DateTime
          label="Bitiş Tarihi *"
          v-model="form.endTime"
          :defaultValue="selectedItem.endTime"
          :state="validateState('endTime')"
        />
      </div>
    </div>

    <Select
      label="İndirim Türü *"
      v-model="form.discountType"
      :defaultValue="selectedItem.discountType"
      :state="validateState('discountType')"
      :options="[
        {
          value: 'Ratio',
          text: 'Oran',
        },
        {
          value: 'Amount',
          text: 'Miktar',
        },
      ]"
    />

    <div class="row">
      <div class="col-6">
        <NumberInput
          label="Min İndirim Oranı / Tutarı *"
          v-model="form.minDiscountRate"
          :defaultValue="selectedItem.minDiscountRate"
          :state="validateState('minDiscountRate')"
        />
      </div>
      <div class="col-6">
        <NumberInput
          label="Max İndirim Oranı / Tutarı *"
          v-model="form.maxDiscountRate"
          :defaultValue="selectedItem.maxDiscountRate"
          :state="validateState('maxDiscountRate')"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <NumberInput
          label="Sabit İndirim Oranı *"
          v-model="form.staticDiscountRate"
          :defaultValue="selectedItem.staticDiscountRate"
          :state="validateState('staticDiscountRate')"
        />
      </div>
      <div class="col-6">
        <Checkbox
          formGroupLabel="Durum *"
          label="Aktif"
          v-model="form.isActive"
          :defaultValue="
            selectedItem.isActive === true || selectedItem.isActive === false
              ? selectedItem.isActive.toString()
              : ''
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
// Utils
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'InsertUpdateOperations',
  mixins: [validationMixin],
  props: {
    confirmModal: Boolean,
    selectedItem: String | Object,
  },
  data() {
    return {
      loading: false,
      products: [],
      form: {
        name: '',
        productId: '',
        productPrice: '',
        startTime: '',
        endTime: '',
        discountType: '',
        minDiscountRate: '',
        maxDiscountRate: '',
        staticDiscountRate: '',
        isActive: true,
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      productPrice: {
        required,
      },
      productId: {
        required,
      },
      startTime: {
        required,
      },
      endTime: {
        required,
      },
      discountType: {
        required,
      },
      minDiscountRate: {
        required,
      },
      maxDiscountRate: {
        required,
      },
      staticDiscountRate: {
        required,
      },
      isActive: {
        required,
      },
    },
  },
  created() {
    this.getProducts();
  },
  methods: {
    getProducts() {
      this.products = [];
      this.$dbFunctions.get('product?Limit=100&IsActive=true').then((res) => {
        if (res.success == true && res.count > 0) {
          res.data.forEach((element) => {
            this.products.push({
              value: element.id,
              text: element.name + ` (${element.price} TL)`,
            });
          });
        }
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    confirm() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.$emit('resetInsertUpdateForm', false);
        return;
      }
      let payload = {
        name: this.form.name,
        productId: this.form.productId,
        productPrice: this.form.productPrice,
        discountType: this.form.discountType,
        startTime: this.form.startTime,
        endTime: this.form.endTime,
        minDiscountRate: this.form.minDiscountRate,
        maxDiscountRate: this.form.maxDiscountRate,
        staticDiscountRate: this.form.staticDiscountRate,
        isActive: this.form.isActive,
      };

      this.$dbFunctions
        .insertOrUpdate(
          `${this.$route.params.companyId}/companyProduct`,
          this.selectedItem.id,
          payload
        )
        .then((res) => {
          this.$dbFunctions.setResponse(this, res);
          this.$emit('resetInsertUpdateForm', true);
          this.formReset();
        })
        .catch((err) => {
          this.$dbFunctions.setResponse(this, err);
          this.$emit('resetInsertUpdateForm', false);
        })
        .finally(() => {});
    },
    formReset() {
      Object.keys(this.form).forEach((key) => (this.form[key] = ''));
      this.$v.$reset();
    },
  },
  watch: {
    confirmModal(newVal) {
      if (newVal) {
        this.confirm();
      }
    },
    selectedItem(newVal) {
      this.formReset();
    },
  },
};
</script>
